<template>
    <div class="main-box">
       <div class="con-box">
            <div class="title">
                <p v-for="(tItem, ti) in tabLists" :key="ti">
                    <router-link :to="{path:tItem.tabUrl}" :class="{ 'active': tabIndex == tItem.id }" @click="onTab(tItem.id)">
                        {{tItem.tabName}}
                        <span style="color: red;" v-if="tItem.isNeed">*</span>
                    </router-link>
                </p>
<!--                <a-button type="primary" style="margin-left: 200px;">生成报告</a-button>-->
            </div>
            <div class="content-box">
                <div style="display: flex;width: 50%;">
                    <div v-if="tableVal !== '纳税评级'" style="display: flex;">
                        <div style="cursor: pointer;" :class="{ 'selcted': y == qunian }" @click="onChangeY(qunian)">
                            {{qunian}}年{{tabLists[tabIndex - 1].tabName}}
                            <img src="../assets/images/right.png" style="margin: 0 5px;">
                        </div>
                        <div style="cursor: pointer;" :class="{ 'selcted': y == qiannian }" @click="onChangeY(qiannian)">
                            {{qiannian}}年{{tabLists[tabIndex - 1].tabName}}
                            <img src="../assets/images/right.png" style="margin: 0 5px;">
                        </div>
                        <div style="cursor: pointer;" :class="{ 'selcted': y == daqiannian }" @click="onChangeY(daqiannian)">{{daqiannian}}年{{tabLists[tabIndex - 1].tabName}}</div>
                        <div style="margin-left: 20px;color: #457dfe;">年度：{{showYear}}年</div>
                    </div>
                    <div style="display: flex;justify-content: center;">
                        <a-select style="margin-left: 20px;" v-model:value="tableVal" @change="onSelectTable" v-if="tabIndex == 4">
                            <a-select-option v-for="(optionItem, oi) in optionList" :key="oi" :value="optionItem.val">{{optionItem.val}}</a-select-option>
                        </a-select>
                        <a-select style="margin-left: 20px;" v-model:value="nhVal" @change="onSelectNH" v-if="tabIndex == 6">
                            <a-select-option v-for="(nhItem, nhi) in nhList" :key="nhi" :value="nhItem.val">{{nhItem.val}}</a-select-option>
                        </a-select>
                        <a-select style="margin-left: 20px;" v-model:value="sbVal" @change="onSelectSB" v-if="tabIndex == 7">
                            <a-select-option v-for="(sbItem, sbi) in sbList" :key="sbi" :value="sbItem.val">{{sbItem.val}}</a-select-option>
                        </a-select>
                    </div>
                </div>
                <div style="display: flex;width: 50%;justify-content: flex-end;">
                    <div style="color: #BBBBBB;margin-right: 20px;display: flex;justify-content: center;align-items: center;">单位：人民币（元）</div>
                    <div style="color: #457dfe;text-decoration: underline;cursor: pointer;margin-right: 20px;display: flex;justify-content: center;align-items: center;"  v-if="tabIndex == 4 || tabIndex == 5 || tabIndex == 6 || tabIndex == 7">
                        <img src="../assets/images/import.png" style="margin: 0 5px;width: 20px;height: 20px;" v-if="tableVal !== '纳税评级' || tabIndex == 5">
                        <a-upload
                            name="file"
                            :action="$uploadXX"
                            :data="{ excel: fileList, ENTERPRISE_ID: orgId }"
                            :show-upload-list="false"
                            :before-upload="beforeUpload"
                            @change="onUpload"
                            v-if="tabIndex == 4 && tableVal === '销项发票'"
                        >
                            <span style="color: #457dfe;text-decoration: underline;font-size: 16px;">导入数据</span>
                        </a-upload>
                        <a-upload
                            name="file"
                            :action="$uploadJX"
                            :data="{ excel: fileList, ENTERPRISE_ID: orgId }"
                            :show-upload-list="false"
                            :before-upload="beforeUpload"
                            @change="onUpload"
                            v-else-if="tabIndex == 4 && tableVal === '进项发票'"
                        >
                            <span style="color: #457dfe;text-decoration: underline;font-size: 16px;">导入数据</span>
                        </a-upload>
                        <a-upload
                            name="file"
                            :action="$uploadGS"
                            :data="{ excel: fileList, ENTERPRISE_ID: orgId }"
                            :show-upload-list="false"
                            :before-upload="beforeUpload"
                            @change="onUpload"
                            v-else-if="tabIndex == 5"
                        >
                            <span style="color: #457dfe;text-decoration: underline;font-size: 16px;">导入数据</span>
                        </a-upload>
                        <a-upload
                            name="file"
                            :action="$uploadNH"
                            :data="{ excel: fileList, ENTERPRISE_ID: orgId, CTYPE: nhVal === '电费' ? 'dianfei' : nhVal === '水费' ? 'shuifei' : 'ranqi' }"
                            :show-upload-list="false"
                            :before-upload="beforeUpload"
                            @change="onUpload"
                            v-else-if="tabIndex == 6"
                        >
                            <span style="color: #457dfe;text-decoration: underline;font-size: 16px;">导入数据</span>
                        </a-upload>
                        <a-upload
                            name="file"
                            :action="$uploadYL"
                            :data="{ excel: fileList, ENTERPRISE_ID: orgId }"
                            :show-upload-list="false"
                            :before-upload="beforeUpload"
                            @change="onUpload"
                            v-else-if="tabIndex == 7 && sbVal === '养老保险'"
                        >
                            <span style="color: #457dfe;text-decoration: underline;font-size: 16px;">导入数据</span>
                        </a-upload>
                        <a-upload
                            name="file"
                            :action="$uplaodOT"
                            :data="{ excel: fileList, ENTERPRISE_ID: orgId, CTYPE: sbVal === '医疗保险' ? 'yiliao' : sbVal === '工伤保险' ? 'gongshang' : sbVal === '生育保险' ? 'shengyu' : 'gongjijin' }"
                            :show-upload-list="false"
                            :before-upload="beforeUpload"
                            @change="onUpload"
                            v-else-if="tabIndex == 7 && (sbVal !== '养老保险')"
                        >
                            <span style="color: #457dfe;text-decoration: underline;font-size: 16px;">导入数据</span>
                        </a-upload>
                    </div>
                    <div style="color: #457dfe;text-decoration: underline;cursor: pointer;display: flex;justify-content: center;align-items: center;" @click="onDownload" v-if="(tabIndex == 4 && tableVal !== '纳税评级') || tabIndex == 5 || tabIndex == 6 || tabIndex == 7">
                        <img src="../assets/images/download.png" style="margin: 0 5px;width: 20px;height: 20px;">
                        下载模板
                    </div>
                    <div style="color: #457dfe;text-decoration: underline;cursor: pointer;" @click="$store.commit('SET_ISEDIT', true)" v-if="tabIndex == 1 || tabIndex == 2 || tabIndex == 3">
                        <img src="../assets/images/edit.png" style="margin: 0 5px;width: 15px;height: 17px;">
                        编辑内容
                    </div>
                </div>
            </div>
        </div>
        <router-view />
    </div>

  <div>
    <a-modal :visible="visible" title="确定要离开此页面吗?" @ok="handleOk" @cancel="visible = false">
      <p>您有数据尚未保存，离开此页面，填写的数据将丢失，请点击最下方保存按钮后离开此页面！</p>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['isEdit', 'y', 'orgId'])
    },
    data () {
        return {
            tabLists: [
                {
                    id: 1,
                    tabName: '资产负债表',
                    isNeed: true,
                    tabUrl: '/ReportForms/ZCFZ',
                    childrenList: []
                },
                {
                    id: 2,
                    tabName: '利润表',
                    isNeed: true,
                    tabUrl: '/ReportForms/LYB',
                    childrenList: []
                },
                {
                    id: 3,
                    tabName: '现金流量表',
                    isNeed: true,
                    tabUrl: '/ReportForms/XJLL',
                    childrenList: []
                },
                // {
                //     id: 4,
                //     tabName: '财税参数表',
                //     isNeed: true,
                //     tabUrl: '/ReportForms/CSCS',
                //     childrenList: []
                // },
                {
                    id: 4,
                    tabName: '经营数据',
                    isNeed: true,
                    tabUrl: '/ReportForms/JYSJ',
                    childrenList: [
                        {
                            id: 51,
                            tabName: '销项发票',
                            isNeed: false,
                            tabUrl: '/ReportForms/JYSJ'
                        },
                        {
                            id: 52,
                            tabName: '进项发票',
                            isNeed: false,
                            tabUrl: '/ReportForms/JXFP'
                        },
                        {
                            id: 53,
                            tabName: '纳税评级',
                            isNeed: false,
                            tabUrl: '/ReportForms/NSPJ'
                        }
                    ]
                },
                {
                    id: 5,
                    tabName: '个税数据',
                    isNeed: true,
                    tabUrl: '/ReportForms/GSSJ',
                    childrenList: []
                },
                {
                    id: 6,
                    tabName: '能耗数据',
                    isNeed: true,
                    tabUrl: '/ReportForms/DFSJ',
                    childrenList: [
                        {
                            id: 71,
                            tabName: '电费',
                            isNeed: false,
                            tabUrl: '/ReportForms/DFSJ'
                        },
                        {
                            id: 72,
                            tabName: '水费',
                            isNeed: false,
                            tabUrl: '/ReportForms/SFSJ'
                        },
                        {
                            id: 73,
                            tabName: '燃气费',
                            isNeed: false,
                            tabUrl: '/ReportForms/RQSJ'
                        }
                    ]
                },
                {
                    id: 7,
                    tabName: '社保数据',
                    isNeed: true,
                    tabUrl: '/ReportForms/YLBX',
                    childrenList: [
                        {
                            id: 81,
                            tabName: '养老保险',
                            isNeed: false,
                            tabUrl: '/ReportForms/YLBX'
                        },
                        {
                            id: 82,
                            tabName: '医疗保险',
                            isNeed: false,
                            tabUrl: '/ReportForms/OTHER?ctype=YL'
                        },
                        {
                            id: 83,
                            tabName: '工伤保险',
                            isNeed: false,
                            tabUrl: '/ReportForms/OTHER?ctype=GS'
                        },
                        {
                            id: 84,
                            tabName: '生育保险',
                            isNeed: false,
                            tabUrl: '/ReportForms/OTHER?ctype=SY'
                        },
                        {
                            id: 85,
                            tabName: '住房公积金',
                            isNeed: false,
                            tabUrl: '/ReportForms/OTHER?ctype=GJJ'
                        }
                    ]
                }
            ],
            qunian: new Date().getFullYear() - 1,
            showYear:new Date().getFullYear() - 1,
            qiannian: new Date().getFullYear() - 2,
            daqiannian: new Date().getFullYear() - 3,
            tabIndex: 1,
            optionList: [
                {
                    id: 1,
                    val: '销项发票'
                },
                {
                    id: 2,
                    val: '进项发票'
                },
                {
                    id: 3,
                    val: '纳税评级'
                }
            ],
            nhList: [
                {
                    id: 1,
                    val: '电费'
                },
                {
                    id: 2,
                    val: '水费'
                },
                {
                    id: 3,
                    val: '燃气费'
                }
            ],
            sbList: [
                {
                    id: 1,
                    val: '养老保险'
                },
                {
                    id: 2,
                    val: '医疗保险'
                },
                {
                    id: 3,
                    val: '工伤保险'
                },
                {
                    id: 4,
                    val: '生育保险'
                },
                {
                    id: 5,
                    val: '住房公积金'
                }
            ],
            fileList: [],
            headers: {
                authorization: 'authorization-text'
            },
            tableVal: '销项发票',
            nhVal: '电费',
            sbVal: '养老保险',
            visible:false,
            clickYear:new Date().getFullYear() - 1
        }
    },
    methods: {
        onTab (i) {
            this.tabIndex = i
            this.tableVal = '销项发票'
            this.$store.commit('SET_YEAR', new Date().getFullYear() - 1)
            this.showYear = new Date().getFullYear() - 1
        },
        onChangeY (ye) {
            console.log(ye)

            if (this.isEdit) {
                // this.$warning({
                //     title: '确定要离开此页面吗?',
                //     content: '您有数据尚未保存，离开此页面，填写的数据将丢失，请点击最下方保存按钮后离开此页面！',
                //     okText: '知道了'
                // })
                this.visible = true;
                this.clickYear = ye;
                return false
            }
            this.showYear = ye;
            this.$store.commit('SET_YEAR', ye)
        },
        handleOk () {
          this.showYear = this.clickYear;
          this.$store.commit('SET_YEAR', this.clickYear);
          this.visible = false;
        },
        getTabIndex () {
            for (let i in this.tabLists) {
                if (this.tabLists[i].tabUrl == this.$route.path) {
                    this.tabIndex = this.tabLists[i].id
                    break
                } else {
                    for (let k in this.tabLists[i].childrenList) {
                        if (this.tabLists[i].childrenList[k].tabUrl == this.$route.fullPath) {
                            this.tabIndex = this.tabLists[i].id
                            if (this.tabLists[i].id == 7) {
                                this.nhVal = this.tabLists[i].childrenList[k].tabName
                            } else if (this.tabLists[i].id == 5) {
                                this.tableVal = this.tabLists[i].childrenList[k].tabName
                            } else if (this.tabLists[i].id == 8) {
                                this.sbVal = this.tabLists[i].childrenList[k].tabName
                            }
                            break
                        }
                    }
                }
            }
        },
        // 上传excel
        onUpload (info) {
            if (info.file.response) {
                if (info.file.response.result === 'success') {
                    this.$message.success('导入成功！')
                    this.$store.commit('SET_XXSTATUS', true)
                } else {
                    this.$message.error('导入失败！')
                }
            }
        },
        beforeUpload (e) {
            this.fileList = e
        },
        // 下载模板
        onDownload () {
            if (this.tabIndex == 4 && this.tableVal == '销项发票') {
                window.location.href = this.$downloadXX
            } else if (this.tabIndex == 4 && this.tableVal == '进项发票') {
                window.location.href = this.$downloadJX
            } else if (this.tabIndex == 5) {
                window.location.href = this.$downloadGS
            } else if (this.tabIndex == 6) {
                window.location.href = this.$downloadNH
            } else if (this.tabIndex == 7 && this.sbVal === '养老保险') {
                window.location.href = this.$downloadYL
            } else if (this.tabIndex == 7 && this.sbVal !== '养老保险') {
                window.location.href = this.$downloadOT
            }
        },
        onSelectTable (e) {
            this.tableVal = e
            if (e === '销项发票') {
                this.$router.push('/ReportForms/JYSJ')
            } else if (e === '进项发票') {
                this.$router.push('/ReportForms/JXFP')
            }  else if (e === '纳税评级') {
                this.$router.push('/ReportForms/NSPJ')
            }
        },
        onSelectNH (e) {
            this.nhVal = e
            if (e === '电费') {
                this.$router.push('/ReportForms/DFSJ')
            } else if (e === '水费') {
                this.$router.push('/ReportForms/SFSJ')
            }  else if (e === '燃气费') {
                this.$router.push('/ReportForms/RQSJ')
            }
        },
        onSelectSB (e) {
            this.sbVal = e
            if (e === '养老保险') {
                this.$router.push('/ReportForms/YLBX')
            } else if (e === '医疗保险') {
                this.$router.push({ path: '/ReportForms/OTHER', query: { ctype: 'YL' } })
                this.$store.commit('SET_SBITEM', 'YL')
            } else if (e === '工伤保险') {
                this.$router.push({ path: '/ReportForms/OTHER', query: { ctype: 'GS' } })
                this.$store.commit('SET_SBITEM', 'GS')
            } else if (e === '生育保险') {
                this.$router.push({ path: '/ReportForms/OTHER', query: { ctype: 'SY' } })
                this.$store.commit('SET_SBITEM', 'SY')
            } else if (e === '住房公积金') {
                this.$router.push({ path: '/ReportForms/OTHER', query: { ctype: 'GJJ' } })
                this.$store.commit('SET_SBITEM', 'GJJ')
            }
        }
    },
    created () {
        this.getTabIndex()
    }
}
</script>
<style lang="less" scoped>
a {
    color: #000;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
                width: 10%;
                text-align: center;
                cursor: pointer;
            }
            .active {
                border: 1px solid #457dfe;
                color: #457dfe;
                padding: 2px 15px;
                border-radius: 20px;
            }
        }
        .content-box{
            margin-top: 20px;
            font-size: 16px;
            padding: 0 10px;
            display: flex;
            .selcted {
                color: #457dfe;
            }
        }
    }
}
</style>
